<template>
    <div class="my-input-global">
        <div class="my-input-container">
            <div v-if="label"
                 :class="['my-input-label', disabled ? 'disabled' : '', status, lblPosition]">
               <span :class="tooltip ? 'tooltip-style' : '' "
                       v-tooltip.top="{
                        content: tooltip
                      }"
               > {{label + ':'}}</span> <span class="required">{{required ? '*' : ''}}</span>
            </div>

            <div class="input-container">
                <img v-if="icon" class="input-icon" :src="icon"/>

                <input :class="[{'with-icon': icon},'my-input', status, {'search-input': searchInput}, getInputPosition]"
                       autocomplete="off"
                       :type="typeInput"
                       ref="inp"
                       @input="updateValue()"
                       @change="changeEvent()"
                       @focus="focusEvent()"
                       @click="clickEvent()"
                       v-on:blur="blurEvent()"
                       v-on:keyup.enter="enterEvent"
                       :value="value"
                       :placeholder="placeholder"
                       :disabled="disabled"
                       :required="required"
                       :name="name"
                       :readonly="readonly"
                />
            </div>


            <div :class="['err-text', status]" v-if="labelErrWrn">
                {{labelErrWrn}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'my-input',
  data: function () {
    return {
      text: null
    }
  },
  props: {
    tooltip: { type: String, default: '' },
    options: {},
    disabled: { type: Boolean },
    required: { type: Boolean },
    readonly: { type: Boolean },
    default: { default: '' },
    label: { type: String },
    name: { type: String },
    placeholder: { type: String },
    value: { type: [String, Number], default: '' },
    icon: { type: String },
    iconScale: {
      default: 0.9
    },
    status: {
      type: String,
      default: '',
      validator: function (value) {
        return ['', 'warning', 'error'].indexOf(value) !== -1
      }
    },
    labelErrWrn: {
      type: String
    },
    searchInput: {
      type: Boolean,
      default: false
    },
    typeInput: { type: String, default: 'text' },
    lblPosition: { type: String, default: 'top' }
  },
  mounted: function () {
    if (this.default) {
      this.$refs.inp.value = this.default
      this.updateValue()
    }
  },
  methods: {
    updateValue: function () {
      this.$emit('input', this.$refs.inp.value)
    },
    blurEvent: function () {
      this.$emit('blur', this.$refs.inp.value)
    },
    enterEvent: function () {
      this.$emit('inputElmec_enterEvent', this.name)
    },
    clearValue: function () {
      this.$refs.inp.value = ''
      this.$emit('input', this.$refs.inp.value)
      this.$emit('clearValue', this.$refs.inp.value)
    },
    clickEvent: function () {
      this.$emit('click', this.$refs.inp.value)
    },
    changeEvent: function () {
      this.$emit('change', this.$refs.inp.value)
    },
    focusEvent: function () {
      this.$emit('focus', this.$refs.inp.value)
    },
  },
  computed: {
    getInputPosition: function () {
      let position = ''

      if (this.lblPosition === 'top') {
        position = 'bottom'
      } else if (this.lblPosition === 'bottom') {
        position = 'top'
      } else if (this.lblPosition === 'left' || this.lblPosition === 'left-form') {
        position = 'right'
      } else if (this.lblPosition === 'right') {
        position = 'left'
      }

      return position
    }
  }
}
</script>

<style lang="less">
    .my-input-global {
        display: flex;
        width: 100%;

        .my-input-container {
            position: relative;
            padding: 0;
            width: 100%;

            .tooltip-style{
                &:hover{
                    text-decoration: underline;
                    cursor: help;
                }

            }

            .input-container {
                display: flex;

                .input-icon {
                    position: absolute;
                    margin: 10px 5px;
                    max-height: 20px;
                    max-width: 20px;
                }

                .with-icon {
                    padding-left: 30px;
                }
            }


            .my-input-label {
                text-align: left;
                display: block;
                letter-spacing: 1px;
                line-height: 20px;
                height: 20px;
                color: gray;

                .required {
                    position: absolute;
                    color: gray;
                }

                &.disabled {
                    opacity: 0.6;
                    color: gray !important;
                }

                &.error {
                }

                &.left {
                    width: 40%;
                    float: left;
                    padding-top: 5px;
                    color: gray;

                    &.disabled {
                        opacity: 0.6;
                        color: gray;
                    }
                }

                &.left-form{
                    width: 40%;
                    float: left;
                    padding-top: 5px;
                }

                &.top {
                    width: 100%;
                    float: left;
                }
            }

            .my-input {
                background-color: #F4F8FB;
                outline: none;
                padding: 4px 25px 5px 10px;
                height: 40px;
                border: 1px solid #C4CBCC;
                border-radius: 3px;
                letter-spacing: 1px;
                color: #33475b;

                &:focus {
                    border: 1px solid #97C1CB;
                    box-shadow: 0 0 3px #0C5E8180;
                }

                &.left, &.right {
                    width: 60%;
                }

                &.top, &.bottom {
                    width: 100%;
                }

                &.search-input {
                    border-radius: 13px;
                    height: 30px;
                }

                &.warning {
                    border: 1px solid orange;
                    box-shadow: 0 0 3px orange;
                }

                &.error {
                    border: 1px solid #CB1229;
                    box-shadow: 0 0 3px #DD102A80;
                }
            }

            input:disabled {
                background-color: #E0E0E0;
                border: 1px solid #C4C4C4;
            }

            .err-text {
                &.error {
                    font-size: 10px;
                    color: darkred;
                }

                &.warning {
                    font-size: 10px;
                    color: orange;
                }
            }
        }
    }
</style>
