<template>
  <div class="col-xs-12 check-box" :class="[getOrderLblCheck, status]">
    <div v-if="checkSlot('custom-label')" @click="changeVal()">
      <slot name="custom-label">
      </slot>
    </div>
    <div v-else-if="lblPosition === 'left' || lblPosition === 'right'" class="container-label" @click="changeVal()"
         :style="{width: getWidth}" :class="[disabled ? 'disabled' : '', status]">
            <span
                :class="tooltip ? 'tooltip-style' : '' "
                v-tooltip.top="{
                    content: tooltip
                  }"
            >{{label}}</span> <span class="required">{{required ? '*' : ''}}</span>
    </div>
    <input
        type='checkbox'
        class="checkbox"
        v-model="checked"
        ref="inp"
        v-bind:id="id"
        @click="updateValue()"
        :disabled="disabled"/>
<!--    <label v-if="checkSlot('custom-label')" v-bind:for='id' class='checkbox-label' @click="changeVal()"></label>-->
    <label v-bind:for='id' class='checkbox-label'></label>
  </div>
</template>

<script>
  export default {
    name: 'check-box',
    props: {
      tooltip: {type: String, default: ''},
      value: {required: true},
      label: {type: String},
      disabled: {type: Boolean, default: false},
      defaultValue: [Boolean],
      lblPosition: {
        type: String,
        default: () => {
          return 'right'
        }
      },
      lblSize: {type: Number},
      um: {type: String},
      required: {type: Boolean},
      status: {
        type: String,
        default: '',
        validator: function (value) {
          return ['', 'warning', 'error'].indexOf(value) !== -1
        }
      }
    },
    watch: {
      value: function () {
        if (typeof (this.value) === 'boolean') {
          this.checked = this.value
        } else if (typeof (this.value) === 'string') {
          this.checked = parseInt(this.value) === 1 || this.value === 'true'
        } else if (typeof (this.value) === 'number') {
          this.checked = this.value === 1
        }
      }
    },
    mounted: function () {
      this.id = this.random()
      if (this.defaultValue) {
        this.value = this.defaultValue
      }
      if (typeof (this.value) === 'boolean') {
        this.checked = this.value
      } else if (typeof (this.value) === 'string') {
        this.checked = parseInt(this.value) === 1 || this.value === 'true'
      } else if (typeof (this.value) === 'number') {
        this.checked = this.value === 1
      }
    },
    data () {
      return {
        id: 0,
        checked: false
      }
    },
    methods: {
      random: function () {
        return parseInt(Math.random() * 100000)
      },
      changeVal: function () {
        this.checked = !this.checked
        this.$emit('input', this.checked)
        this.$emit('change', this.checked)
      },
      updateValue: function () {
        this.$emit('input', this.$refs.inp.checked)
        this.$emit('change', this.$refs.inp.checked)
      },
      checkSlot: function (slotActive) {
        return !!this.$slots[slotActive]
      }
    },
    computed: {
      getWidth: function () {
        let width = 'auto'

        if (this.lblPosition === 'left' || this.lblPosition === 'right') {
          if (this.lblSize && this.um) {
            width = this.lblSize.toString() + this.um
          }
        } else {
          width = '100%'
        }

        return width
      },
      getOrderLblCheck: function () {
        let classTxt = 'lbl-check'

        if (this.lblPosition === 'right') {
          classTxt = 'check-lbl'
        }

        return classTxt
      }
    }
  }
</script>

<style lang="less">
  /* checkbox personalizzato */

  .check-box {
    height: 100%;
    width: 100%;
    display: flex;
    /*height: 20px;*/

    &.lbl-check {
      flex-direction: row;

      .container-label {
        padding-top: 1px;
        margin: 0 5px 0 0;
      }
    }

    &.check-lbl {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .container-label {
        padding-top: 1px;
        margin: 0 0 0 5px;
      }
    }

    &.warning {
      .checkbox-label::before {
        border: 1px solid orange;
        box-shadow: 0 0 3px orange;
      }

      .checkbox + label::after {
        border: 1px solid orange;
        box-shadow: 0 0 3px orange;
      }
    }

    &.error {
      .checkbox-label::before {
        border: 1px solid red;
        box-shadow: 0 0 3px #DD102A80;
      }

      .checkbox + label::after {
        border: 1px solid red;
        box-shadow: 0 0 3px #DD102A80;
      }
    }

    .container-label {
      height: 100%;
      letter-spacing: 1px;
      line-height: 15px;
      cursor: pointer;
      color: #33475b;

      .required {
        position: absolute;
        font-size: 20px;
        color: #33475b;
      }

      .tooltip-style {
        &:hover {
          text-decoration: underline;
          cursor: help;
        }
      }
    }

    .checkbox {
      display: none;
    }

    .checkbox-label {
      cursor: pointer;
      margin-right: 20px;
    }

    .checkbox-label::before {
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      content: "";
      position: absolute;
      z-index: 1;
      width: 20px;
      height: 20px;
      background-color: white;
      border: 1px solid #999999;
      border-radius: 4px;
    }

    .checkbox:checked + label::before {
      -webkit-transform: rotate(-45deg) translate(0px, 0px);
      -ms-transform: rotate(-45deg) translate(0px, 0px);
      transform: rotate(-45deg) translate(0px, 0px);
      width: 14px;
      height: 8px;
      border: 2px solid #EFF3F3;
      border-radius: 0;
      border-top-style: none;
      border-right-style: none;
      background-color: transparent;
      margin-top: 4px;
      margin-left: 3.5px;
      box-shadow: none;
    }

    .checkbox + label::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      margin: 0;
      background-color: #2C5693;
      border-radius: 4px;
      box-shadow: none;
    }

    input[type=checkbox] + .lbl {
      z-index: 0;
    }
  }

  /* fine checkbox personalizzato */

</style>
