<template>
  <div id="cart" :style="{'max-height': (myHeight) + 'px'}">
    <h5>Riepilogo dispositivi</h5>

    <div class="cart-content">

      <div v-for="(item, index) in $store.getters.cart" :key="index">
        <div class="device-container">
          <div class="device-icon">
            <img :src="getImgByDevice(item.device)"/>
          </div>

          <div class="device-text">
            <span class="device">{{ item.device }}</span>
            <span class="band">{{ item.band }}</span>
          </div>
          <img class="basket" v-if="actionBtns" src="/img/configuration/cestino.svg" @click="deleteItem(index)" height="20"/>
        </div>

        <table class="item-info">
          <tr><td class="label">Quantità:</td> <td class="value">{{ item.quantity }}</td></tr>
          <tr><td class="label">Durata del contratto:</td> <td class="value">{{ item.contractLife }}</td></tr>
          <tr><td class="label">Vendor:</td> <td class="value multi"><img :src="getImgByVendor(item.vendor)"/></td></tr>
          <tr v-if="item.accessories.length > 0"><td class="label nowrap">Add on Servizio:</td></tr>
          <tr v-for="a in item.accessories" :key="a"><td class="value multi nowrap"><img class="img" src="/img/lego.svg">{{ a }}</td></tr>
          <tr v-if="item.activableServices.length > 0"><td class="label nowrap">Add on User:</td></tr>
          <tr v-for="as in item.activableServices" :key="as"><td class="value multi nowrap"><img class="img" src="/img/lego.svg">{{ as }}</td></tr>
        </table>

        <separator v-if="index < $store.getters.cart.length - 1" theme="gray"/>
      </div>

    </div>


    <separator v-if="actionBtns" theme="gray"/>

    <div v-if="actionBtns" class="container">
      <div class="justify-content-center">
        <a @click="richiediPreventivo" class="btn custom solid-btn page-scroll">Richiedi il preventivo</a>
      </div>
    </div>
  </div>
</template>

<script>
  import {commonMixins} from '../mixins/common-mixins'
  import Separator from './global/Separator'

  export default {
    name: 'Carrello',
    props: {
      actionBtns: {
        type: Boolean,
        default: true
      },
      customHeight: {
        type: Number,
        default: null
      }
    },
    data () {
      return {
        myHeight: window.innerHeight
      }
    },
    components: {Separator},
    mixins: [commonMixins],
    mounted: function () {
      if (this.customHeight) {
        this.myHeight = this.customHeight
      }
    },
    methods: {
      richiediPreventivo: function () {
        this.$router.push({
          name: 'PreventivoNoleggio'
        })
      },
      deleteItem: function (index) {
        this.$store.dispatch('removeCart', index)
      },
      getImgByDevice: function (value) {
        return this.deviceOptions.filter(item => {
          return item.value === value
        })[0].img
      },
      getImgByVendor: function (value) {
        return this.vendorOptions.filter(item => {
          return item.value === value
        })[0].img
      }
    }
  }
</script>

<style lang="less">
#cart {
  overflow-y: auto;
  padding: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: absolute;
  background-color: #E8EDF1;
  width: 300px;
  right: 10%;

  @media (max-width: 700px) {
    position: unset;
  }
  @media (max-width: 1400px) {
    right: 2%;
  }

  h5 {
    color: black;
  }

  /* CART CONTENT STYLE */
  .cart-content {
    margin: 25px 0;
    font-size: 12px;

    .separator {
      margin: 25px 0;
    }

    .device-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      .device-icon {
        width: 60px;
        text-align: center;

        img {
          max-height: 50px;
        }
      }

      .device-text {
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;

        span {
          font-size: 13px;
          font-weight: bold;
          color: #2C5693;
        }
      }

      .basket {
        cursor: pointer;
      }
    }

    .item-info {
      .multi {
        display: flex;
        align-items: center;

        .img {
          margin-right: 5px;
        }
      }

      .nowrap {
        white-space: nowrap;
      }

      .label {
        font-weight: bold;
        color: black;
        padding-top: 10px;
      }

      .value {
        padding-left: 5px;
        color: #33475b;

        img {
          max-height: 20px;
          max-width: 50px;
        }
      }
    }
  }


  /* BTN STYLE */
  .container {
    .justify-content-center {
      text-align: center;
      .btn {
        &.custom {
          cursor: pointer;
          margin-top: 20px;
          height: 40px;
          padding: 10px 20px;
          white-space: nowrap;
        }
      }
    }
  }

}
</style>
