<template>
  <div class="separator clearfix" :class="[theme]">
    <div v-if="title"
         class="col-xs-12 noPadding title"
         v-html="title"
    >
    </div>

    <div v-if="description"
         class="col-xs-12 noPadding description"
         v-html="description"
    >
    </div>
  </div>
</template>

<script>
  export default {

    components: {},
    name: 'separator',
    props: {
      theme: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      }
    },
    data () {
      return {}
    },
    mounted: function () {
    },
    methods: {}
  }
</script>

<style lang="less">

  .separator {
    border-bottom: 1px solid #DADCE0;

    &.gray {
      border-color: darkgray;
    }

    .title {
      color: gray;
      font-size: 15px;
      padding-bottom: 5px;
    }

    .description {
      color: gray;
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
</style>
